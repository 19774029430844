import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "@reach/router";
import Loader from "components/loader";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import http from "utils/http";
import { endpoints } from "utils/endpoints";
import DownloadIcon from "assets/downloadtool.jpg";
import CopyIcon from "assets/copytools.jpg";
import { Tooltip } from "react-tooltip";
import CleanButton from "assets/cleanBtn.svg";
import Layout from "components/layout";
import SEO from "components/seo";
import { direction } from "utils/direction";

function FirstSummaryTool() {
  const location = useLocation();
  const [toggleTool, setToggleTool] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [summarizedText, setSummarizedText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [messageText, setMessageText] = useState("");
  const [copySuccess, setCopySuccess] = useState("Copy");
  const refDiv = useRef(null);
  const summarizedTextRef = useRef(null);
  const [hasSummary, setHasSummary] = useState(false);
  const [error, setError] = useState(false);
     const [inputLang, setInputLang] = useState("");
           
const {
    register,

    reset,
    formState: { errors },
  } = useForm();

 

  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  const displayShareId = UpdatreshareId;

  useEffect(() => {
    const fetchAnalysis = async () => {
      if (!displayShareId) return;
      try {
        const response = await http().get(
          `${endpoints.forms.aiTools}/${displayShareId}`
        ); 
        const { user_text: sourceText, source_language_code:sourceCode } = response?.meta;
        setInputLang(sourceCode)
        if (response.result ) {
          setEnteredText(sourceText);
         if (Array.isArray(response.result)) {
            setSummarizedText(
              response.result.map((item) => item.summarized_text).join("\n")
            );
          } else if (
            typeof response.result === "object" 
          ) {
            setSummarizedText(response.result.summarized_text);
          }
         
          setToggleTool(true);
          reset();
          window.scrollTo(0, 0);
        } else {
          console.log(response.message);
        }

        setWordCount(
          sourceText
            ? sourceText.split(/\s+/).filter((word) => word.length > 0).length
            : 0
        );
        setMessageText(sourceText);
        reset({ message: sourceText });
      } catch (err) {
        setError(err.message || "An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnalysis();
  }, [displayShareId]);

  const handleTextChange = (e) => {
    const text = e.target.value.trim();
    setMessageText(text);
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    setWordCount(words.length);
    setHasSummary(false);
  };

 const copyText = () => {
    if (summarizedTextRef.current) {
      const textToCopy = summarizedTextRef.current.value;
   
      navigator.clipboard.writeText(textToCopy);
      setCopySuccess("Copied");
      setTimeout(() => {
        setCopySuccess("Copy");
      }, 1500);
    }
  };

  const downloadCSV = () => {
    if (!summarizedText) return;

    const csvData = `Summarized Text\n"${summarizedText.replace(/"/g, '""')}"`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "summarized_text.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const title = "Free Online Summarization Tool";
  const description =
    "The Tomedes summarization tool creates summaries of texts. You simply input a URL or paste in copy and the tool summarizes the information you have fed in. Ideal for use by translators, writers, content marketers and others who work on translation or with words on a daily basis.";
  const keywords =
    "summary, summarizer, summarizing, summarizing tool, text summary, article summary, document summary";
  if (loading)
    return (

        <div className="grid place-content-center h-[calc(100vh-150px)]">
          <Loader />
        </div>

    );
  return (
    <Layout>
    <SEO
      title={title}
      description={description}
      keywords={keywords}
      slug="/tools/text-summarizer"
    />
    <div className="bg-[#F5F5F5] ">
      <div className=" w-full py-7 ">
        <ToolsBreadcrumb />
      </div>

      <div className="pb-[120px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 mt-12 lg:mt-16">
          <h1 className="text-[#5B93FF] font-semibold text-base font-primary text-center ">
            SUMMARY GENERATOR
          </h1>
          <h2 className="mt-3 text-[#0A2641] font-primary text-[28px] lg:text-[64px] font-bold leading-[48px] lg:leading-[77px] text-center ">
            Summarize your long text for free
          </h2>
          <div className="grid lg:grid-cols-2 gap-3 mt-16">
            <div className="bg-[#ffffff] rounded-lg shadow-lg p-5">
              <div className="">
      
                <div>
               
                    <div className="flex items-start gap-4 relative">
                      <textarea
                        name="message"
                        id="message"
                        // {...register("message")}
                        dir={direction(inputLang)}
                        value={messageText}
                        dangerouslySetInnerHTML={{__html: messageText.replace(/\n/g, "<br />")}}
                        className={`anonymize_tool   min-h-[280px] font-opensans placeholder-[#858585] text-black font-normal leading-[26px] pb-3  ${direction(inputLang) === "rtl" ? "pl-[40px]" : "pr-[40px]" } bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block w-full`}
                        placeholder="Type or paste your text here"
                        onChange={handleTextChange}
                        readOnly
                      >
                     
                      </textarea>
                      {messageText && (
                        <img
                          src={CleanButton}
                          alt="clean-button"
                          onClick={()=>navigate(`/tools/text-summarizer/`)}
                          className="cursor-pointer  absolute right-[14px]"
                        />
                      )}
                    </div>
           
                  <div className="flex justify-between items-center  mt-8">
              
                      <p className="text-right mt-2 text-sm text-[#9C9C9C] font-semibold font-opensans">
                        {wordCount} words
                      </p>
                    
                    <div className="flex gap-2">
               
                  
         
                      <button
                         disabled={summarizedText || !messageText }
                        type="button"
                   
              
                        className={` bg-[#E8E8E8] text-[#858585] cursor-not-allowed px-4 py-2 rounded-md`}
                      >
                        Summarize text
                      </button>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
            <div
              style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
              className="bg-[#ffffff] relative  rounded-lg shadow-lg p-5"
            >
              <div className="flex justify-between gap-1">
                <p className="text-base text-[#B0B0B0] font-bold leading-6 font-opensans">
                  Summarized text
                </p>
              </div>
              <div className="mt-4">
                <textarea
                dir={direction(inputLang)}
                   value={summarizedText || error.message || ""}
                  ref={summarizedTextRef}
                  className={`tool min-h-[280px] border-none outline-none font-opensans placeholder-[#858585] ${error.message ? "text-[#ff0000]" : "text-[#6E6E6E]"}  leading-[26px]  bg-transparent text-sm overscroll-y-none resize-none  block w-full`}
                  readOnly
                />
              </div>
              <div className="mt-5">
     
                  <div className=" px-4 absolute bottom-0 left-0 py-6 w-full flex flex-wrap items-center justify-end ">
                    <div className="flex justify-between w-full">
                      <p className="text-[#9C9C9C] font-semibold font-opensans text-sm">
                        {
                          summarizedText
                            .split(/\s+/)
                            .filter((word) => word.length > 0).length
                        }{" "}
                        words
                      </p>
                      <div className="flex gap-4 ">
                        <button
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content={copySuccess}
                          onClick={copyText}
                        >
                          <img src={CopyIcon} alt="/copyicons" />
                        </button>
                        <button
                          data-tooltip-id="download-tooltip"
                          data-tooltip-content="Download"
                          onClick={downloadCSV}
                        >
                          <img src={DownloadIcon} alt="/downloadicon" />
                        </button>
                      </div>
                    </div>
                    <Tooltip
                      id="copy-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                    <Tooltip
                      id="download-tooltip"
                      place="top"
                      multiline={true}
                      className="z-50"
                    />
                  </div>
                
              </div>
            </div>
          </div>
        </div>
        <p className="w-full text-center text-base mt-12 pb-4 text-[#9C9C9C]">
          Assisted by AI tools. Inaccuracies may occur.
        </p>
        <p className="mt-8 md:mt-12  px-4 sm:px-6 font-opensans max-w-3xl mx-auto text-[#424242] text-lg font-normal text-center">
          Our text summarizer helps you quickly generate concise summaries from
          articles, PDFs, and websites. Just paste your content or a URL, and
          get a summary instantly.
        </p>
      </div>
    </div>
    </Layout>
  );
}

export default FirstSummaryTool;
